<template>
  <main>
    <PageTitle>{{contentData.title[0].value}}</PageTitle>

    <div class="title-spacer"></div>

    <div
      ref="container1"
      class="text-container text-container--1 text--z-depth-1"
    >
      <p ref="text1" v-html="contentData.field_absatz_1[0].value"></p>
    </div>
    <div
      ref="img1"
      class="img img--1 z-depth-1"
      :style="{ backgroundImage: `url(${contentData.field_bild_1[0].url})` }"
    ></div>

    <div
      ref="container2"
      class="text-container text-container--2 text--z-depth-1"
    >
      <p ref="text2" v-html="contentData.field_animationabsatz[0].value"></p>
    </div>


    <div 
      ref="img2" 
      class="img img--2 z-depth-1" 
      v-for="(url, i) in img2URLs"
      :key="'img2-' + i"
      v-show="i === currentImage || i === currentImage + 1"
      :style="{ backgroundImage: `url(${url})` }"
    ></div>

    <div
      ref="container3"
      class="text-container text-container--3 text--z-depth-1"
    >
      <p ref="text3" v-html="contentData.field_adresse[0].value"></p>
    </div>
    <div ref="map" class="img img--3 z-depth-1"></div>
  </main>
</template>

<script>
import { httpMixin } from "@/mixins/httpMixin";
import ScrollMagic from "scrollmagic";
import PageTitle from "@/components/PageTitle";
import { incrementComponentsLoading } from "@/events";
import imagePreloader from "../helpers/image-preloader";
import mapboxgl from "mapbox-gl";

export default {
  mixins: [httpMixin],

  components: { PageTitle },

  data: function() {
    return {
      animationController: new ScrollMagic.Controller(),
      currentImage: 13,
      img2URLs: [
        require("@/media/about_planning0.png"),
        require("@/media/about_planning1.png"),
        require("@/media/about_planning2.png"),
        require("@/media/about_planning3.png"),
        require("@/media/about_planning4.png"),
        require("@/media/about_planning5.png"),
        require("@/media/about_planning6.png"),
        require("@/media/about_planning7.png"),
        require("@/media/about_planning8.png"),
        require("@/media/about_planning9.png"),
        require("@/media/about_planning10.png"),
        require("@/media/about_planning11.png"),
        require("@/media/about_planning12.png"),
        require("@/media/about_planning13.png"),
        require("@/media/about_planning14.png")
      ],
      contentData: {
        title: [{value: ""}],
        field_absatz_1: [{value: ""}],
        field_bild_1: [{url: ""}],
        field_animationabsatz: [{value: ""}],
        field_adresse: [{value: ""}]
      }
    };
  },

  created: function() {
    const self = this;
    this.componentsLoading += 2;

    Promise.all([
          imagePreloader.loadImage(require("@/media/about_planning7.png")),
          imagePreloader.loadImage(require("@/media/about_planning8.png")),
          imagePreloader.loadImage(require("@/media/about_planning9.png")),
          imagePreloader.loadImage(require("@/media/about_planning10.png"))
        ])
        .then(() => {
          self.componentsLoading--;
          return Promise.all([
            imagePreloader.loadImage(require("@/media/about_planning3.png")),
            imagePreloader.loadImage(require("@/media/about_planning4.png")),
            imagePreloader.loadImage(require("@/media/about_planning5.png")),
            imagePreloader.loadImage(require("@/media/about_planning6.png"))
          ]);
        })
        .then(() => {
          return Promise.all([
            imagePreloader.loadImage(require("@/media/about_planning0.png")),
            imagePreloader.loadImage(require("@/media/about_planning1.png")),
            imagePreloader.loadImage(require("@/media/about_planning2.png"))
          ]);
        })
        .catch(console.error)

    this.fetchFromAPI("content/about")
      .then(content => {
        self.$set(this, "contentData", content[0]);
        self.componentsLoading--;
      })
      .catch(self.handleError);
  },

  mounted: function() {
    const self = this;
    setInterval(function() {
      self.currentImage--;
      if (self.currentImage < 0) self.currentImage = self.img2URLs.length - 1;
    }, 900);

    mapboxgl.accessToken =
      "pk.eyJ1IjoidGlnZXJmYWNlIiwiYSI6ImNrcDJsMXJ0NDFmaG8yb254Njg4Mjlwa3IifQ.M8m3ZDW82VQuR1tDNCQBLw";
    const officeLocation = [11.421600739528694, 47.278401928963774];
    const map = new mapboxgl.Map({
      container: this.$refs.map,
      style: "mapbox://styles/mapbox/dark-v10",
      center: officeLocation,
      zoom: 16
    });
    map.addControl(new mapboxgl.FullscreenControl());

    map.on("load", function() {
      map.loadImage("/media/Punktgenau-Punkt.png", function(error, image) {
        if (error) throw error;
        map.addImage("point", image);
        map.addSource("office", {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: [
              {
                type: "Feature",
                geometry: {
                  type: "Point",
                  coordinates: officeLocation
                }
              }
            ]
          }
        });
        map.addLayer({
          id: "points",
          type: "symbol",
          source: "office",
          layout: { "icon-image": "point" }
        });
      });
    });

    this.slideEnter(this.$refs.container1, this.$refs.text1, 0, 0.7);
    this.slideEnter(this.$refs.container1, this.$refs.img1);
    this.slideEnter(this.$refs.container2, this.$refs.text2, 0, 0.7);
    this.slideEnter(this.$refs.container3, this.$refs.text3, 0, 0.7);
    this.slideEnter(this.$refs.container3, this.$refs.map);
  },

  methods: {
    slideEnter(triggerElement, target, offsetPercent = 0, triggerHook = 0.8) {
      target.classList.add("slide-enters");
      new ScrollMagic.Scene({
        triggerElement,
        offset: (triggerElement.scrollHeight * offsetPercent) / 100,
        triggerHook,
        reverse: false
      })
        .setClassToggle(target, "slide-enters--entered")
        .addTo(this.animationController);
    }
  },

  beforeRouteLeave(to, from, leave) {
    this.$emit(incrementComponentsLoading);
    leave();
  }
};
</script>

<style scoped>
.slide-enters {
  transform: translateY(80px);
  opacity: 0;
  transition: transform 0.7s;
}

.slide-enters--entered {
  transform: none;
  opacity: 1;
}

.title-spacer {
  height: 15.45vw;
  max-height: 222px;
}
.text-container {
  text-align: left;
  margin-bottom: 40px;
}

.text-container--1 {
  grid-row: 3 / 4;
}

.text-container--2 {
  grid-row: 5 / 6;
}

.text-container--3 {
  grid-row: 7 / 8;
}

.img {
  background-position: center;
  background-size: cover;
  height: 42.86vw;
}

.img--1 {
  grid-row: 2 / 3;
  transition: background-image 1s ease-in-out;
}

.img--2 {
  grid-row: 4 / 5;
}

.img--3 {
  grid-row: 6 / 7;
}

@media screen and (min-width: 768px) {
  .text-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
  }

  .text-container--1 {
    text-align: left;
    grid-row: 2 / 3;
  }

  .text-container--1 > p {
    margin-block-start: 0;
  }

  .text-container--2 {
    text-align: left;
    justify-content: center;
    grid-row: 3 / 4;
  }

  .text-container--3 {
    text-align: right;
    justify-content: flex-end;
    grid-row: 4 / 5;
  }

  .img {
    height: 28.6vw;
  }

  .img--1 {
    grid-column: 2 / 4;
    grid-row: 2 / 3;
  }

  .img--2 {
    grid-column: 1 / 3;
    grid-row: 3 / 4;
  }

  .img--3 {
    grid-column: 2 / 4;
    grid-row: 4 / 5;
  }
}

@media screen and (min-width: 1025px) {
  .img {
    height: 21.42vw;
    max-height: 309px;
  }
}
</style>
